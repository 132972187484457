'use client';

import { createContext, useContext, ReactNode } from 'react';

import { ParsedOffer } from 'src/utils/parse-offer';

export type OfferContextProviderProps = {
  children: ReactNode;
  data: ParsedOffer | null;
};

export const OfferContext = createContext<ParsedOffer | null>(null);

export function OfferContextProvider({ children, data }: OfferContextProviderProps) {
  return <OfferContext.Provider value={data}>{children}</OfferContext.Provider>;
}

export default function useOffer(): ParsedOffer | null {
  return useContext(OfferContext) ?? null;
}
