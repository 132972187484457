import { allowedDomainList } from './sanitize-redirect.constants';
import { IsAllowedDomain, SanitizeRedirect } from './sanitize-redirect.models';

const isAllowedDomain = ({ hostname, domain }: IsAllowedDomain) => {
  return hostname === domain || hostname.endsWith(`.${domain}`);
};

const sanitizeRedirect = ({ redirect }: SanitizeRedirect) => {
  if (!redirect) {
    return null;
  }

  try {
    const redirectUrl = new URL(redirect);
    const { hostname } = redirectUrl;

    if (!allowedDomainList.some((domain) => isAllowedDomain({ hostname, domain }))) {
      return null;
    }

    return redirectUrl.toString();
  } catch {
    return null;
  }
};

export default sanitizeRedirect;
