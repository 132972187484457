import { useCallback } from 'react';
import { useSearchParams } from 'next/navigation';

import sanitizeRedirect from 'src/utils/sanitize-redirect';
import { QueryParameters } from './use-query-parameters.models';

function useQueryParameters(): QueryParameters {
  const parameters = useSearchParams();

  const createQueryString = useCallback(
    (queryParams: { name: string; value: string }[]) => {
      const params = new URLSearchParams(parameters.toString());

      if (params.has('globalError')) {
        params.delete('globalError');
      }

      if (params.has('entryFeedback')) {
        params.delete('entryFeedback');
      }

      for (const { name, value } of queryParams) {
        params.set(name, value);
      }

      return params.toString();
    },
    [parameters],
  );

  return {
    returnUrl: sanitizeRedirect({ redirect: parameters.get('returnUrl') }),
    userId: parameters.get('userId'),
    token: parameters.get('token'),
    offerId: parameters.get('oid'),
    applicationProcessId: parameters.get('apid'),
    oneClickApplyStatus: parameters.get('oca'),
    attachedCV: parameters.get('acv'),
    loginToken: parameters.get('loginToken'),
    activationToken: parameters.get('activationToken'),
    triggerMail: parameters.get('tm'),
    passwordToken: parameters.get('passwordToken'),
    authProcessId: parameters.get('aupid'),
    processCurrentStage: parameters.get('pcst'),
    accountType: parameters.get('act'),
    processType: parameters.get('pct'),
    multiAtsId: parameters.get('maid'),
    emailOriginId: parameters.get('emailOriginId'),
    parameters,
    createQueryString,
  };
}

export default useQueryParameters;
